import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";

import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

const API_BASE_URL = "https://ecoinwebback.tauschet.com/api/auth";

const Admins = ({ toggleDarkMode, darkMode }) => {
  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newAdminEmail, setNewAdminEmail] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/admins`);
        setAdmins(response.data);
      } catch (error) {
        console.error("Error fetching admins:", error);
        message.error("Failed to fetch admins.");
      }
    };

    fetchAdmins();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddAdmin = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/addadmin`, {
        email: newAdminEmail,
      });

      // Assuming the backend returns the new admin's data when successfully added
      const newAdmin = response.data;
      setAdmins([...admins, newAdmin]);
      setShowAddModal(false);
      setNewAdminEmail("");

      // Show success message
      message.success("Admin added successfully!");
    } catch (error) {
      console.error("Error adding admin:", error);

      // Extract the error message from the response, if available
      const errorMessage = error.response?.data?.error;

      if (errorMessage === "Email already registered") {
        // Handle specific case where email is already registered
        message.warning("This email is already registered as an admin.");
      } else {
        // Handle other errors
        message.error(errorMessage || "Failed to add admin. Please try again.");
      }
    }
  };

  const handleRowClick = (admin) => {
    setSelectedAdmin(admin);
    setShowModal(true);
  };

  // const handleEditAdmin = (admin) => {
  //   setSelectedAdmin(admin);
  //   setShowEditModal(true);
  // };

  const handleDeleteAdmin = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/admin/${id}`);
      setAdmins(admins.filter((admin) => admin.id !== id));
      message.success("Admin deleted successfully!");
    } catch (error) {
      console.error("Error deleting admin:", error);
      message.error("Failed to delete admin.");
    }
  };
  const getRowStyle = (admin) => {
    if (!admin.name || !admin.phoneNumber) {
      return { backgroundColor: "#f8d7da" }; // Light red background for inactive rows
    }
    return {}; // No special styling if active
  };
  // const handleSaveEdit = async () => {
  //   try {
  //     await axios.put(`${API_BASE_URL}/admin/${selectedAdmin.id}`, {
  //       email: selectedAdmin.email,
  //       phoneNumber: selectedAdmin.phoneNumber
  //     });
  //     setShowEditModal(false);
  //     message.success('Admin details updated successfully!');
  //   } catch (error) {
  //     console.error('Error updating admin:', error);
  //     message.error('Failed to update admin details.');
  //   }
  // };

  const getBankAddresses = (accounts) => {
    return accounts
      .filter((acc) => acc.type === "bank")
      .map((account, index) => (
        <li key={index}>
          {account.acc_name} - {account.identification}
        </li>
      ));
  };

  const getWalletAddresses = (accounts) => {
    return accounts
      .filter((acc) => acc.type === "wallet")
      .map((account, index) => (
        <li key={index}>
          {account.acc_name} - {account.identification}
        </li>
      ));
  };

  return (
    <div
      className={`flex h-screen ${
        darkMode ? "bg-gray-800 text-black" : "bg-gray-100 text-gray-900"
      }`}
    >
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow">
        <Header
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-grow p-20 overflow-auto">
          <div className="container mx-auto">
            <header className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-bold">Admins</h1>
              <button
                onClick={() => setShowAddModal(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add Admin
              </button>
            </header>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow-md">
                <thead className="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Name</th>
                    <th className="py-2 px-4 border-b text-left">Email</th>
                    <th className="py-2 px-4 border-b text-left">
                      Phone Number
                    </th>
                    <th className="py-2 px-4 border-b text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin, index) => (
                    <tr
                      key={admin.id || index}
                      onClick={() => handleRowClick(admin)}
                      style={getRowStyle(admin)}
                      className={`cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition`}
                    >
                      <td className="py-2 px-4 border-b">{admin.name}</td>
                      <td className="py-2 px-4 border-b">{admin.email}</td>
                      <td className="py-2 px-4 border-b">
                        {admin.phoneNumber}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {/* <button 
                          onClick={(e) => { e.stopPropagation(); handleEditAdmin(admin); }} 
                          className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 transition mr-2"
                        >
                          Edit
                        </button> */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteAdmin(admin.id);
                          }}
                          className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>

        {/* Admin Details Modal */}
        {showModal && selectedAdmin && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">
                {selectedAdmin.name}'s Details
              </h2>
              <h3 className="text-lg font-semibold mb-2">Bank Addresses</h3>
              <ul className="list-disc ml-6 mb-4">
                {selectedAdmin.accounts ? (
                  getBankAddresses(selectedAdmin.accounts)
                ) : (
                  <li>No bank addresses</li>
                )}
              </ul>
              <h3 className="text-lg font-semibold mb-2">Wallet Addresses</h3>
              <ul className="list-disc ml-6">
                {selectedAdmin.accounts ? (
                  getWalletAddresses(selectedAdmin.accounts)
                ) : (
                  <li>No wallet addresses</li>
                )}
              </ul>
              <div className="flex justify-end gap-2 mt-4">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Edit Admin Modal */}
        {/* {showEditModal && selectedAdmin && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Edit {selectedAdmin.name}</h2>
              <form>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Email</label>
                  <input
                    type="email"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={selectedAdmin.email}
                    onChange={(e) => setSelectedAdmin({ ...selectedAdmin, email: e.target.value })}
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Phone Number</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={selectedAdmin.phoneNumber}
                    onChange={(e) => setSelectedAdmin({ ...selectedAdmin, phoneNumber: e.target.value })}
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button 
                    type="button" 
                    onClick={handleSaveEdit} 
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    Save
                  </button>
                  <button 
                    type="button" 
                    onClick={() => setShowEditModal(false)} 
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )} */}

        {/* Add Admin Modal */}
        {showAddModal && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Add New Admin</h2>
              <form>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={newAdminEmail}
                    onChange={(e) => setNewAdminEmail(e.target.value)}
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    type="button"
                    onClick={handleAddAdmin}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={() => setShowAddModal(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admins;
