import React, { useContext } from 'react';
import { ChatContext } from '../../context/ChatContext';
import { useCryptoState } from '../../context/AuthContext';

const PotentialChats = () => {
  const { user } = useCryptoState();
  const { potentialChats, createChat, onlineUsers } = useContext(ChatContext);

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {potentialChats &&
        potentialChats.map((u, index) => (
          <div
            className="relative bg-blue-500 text-white cursor-pointer py-1 px-2 rounded-md"
            key={u.id}
            onClick={() => createChat(String(user.id), String(u.id))}
          >
            {u.name}
            {onlineUsers?.some((onlineUser) => onlineUser.userId === String(u.id)) && (
              <span className="absolute top-0 right-0 h-3 w-3 rounded-full bg-green-500"></span>
            )}
          </div>
        ))}
    </div>
  );
};

export default PotentialChats;
