import React, { useContext } from 'react';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient';
import { ChatContext } from '../../context/ChatContext';
import { unreadNotificationsFunc } from '../../utils/unreadNotifications';
import moment from 'moment';
import { useFetchLatestMessage } from '../../hooks/useFetchLatestMessage';

const UserChat = ({ chat, user }) => {
  const { recipientUser } = useFetchRecipientUser(chat, user);
  const { onlineUsers, notifications, markThisUserNotificationAsRead } = useContext(ChatContext);
  const { latestMessage } = useFetchLatestMessage(chat);
  const unreadNotifications = unreadNotificationsFunc(notifications);
  const personalNotification = unreadNotifications?.filter(
    (n) => n.senderId === String(recipientUser?.id)
  );
  const isOnline = onlineUsers?.some((user) => user?.userId === String(recipientUser?.id));
  
  const truncateText = (text) => {
    let shortText = text.substring(0, 40);

    if (text.length > 40) {
      shortText += '...';
    }
    return shortText;
  };

  return (
    <div
      className="p-4 flex justify-between items-center cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 transition-all duration-200"
      role="button"
      onClick={() => {
        if (personalNotification?.length !== 0) {
          markThisUserNotificationAsRead(personalNotification, notifications);
        }
      }}
    >
      <div className="flex items-start space-x-3">
        <div>
          <div className="font-medium text-lg text-gray-900">{recipientUser?.name}</div>
          <div className="text-gray-600 dark:text-gray-300">
            {latestMessage?.text && <span>{truncateText(latestMessage?.text)}</span>}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end space-y-1">
        {personalNotification?.length > 0 && (
          <div className="bg-red-500 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
            {personalNotification.length}
          </div>
        )}
        {isOnline && <span className="h-3 w-3 rounded-full bg-green-500"></span>}
        <div className="text-gray-500 text-sm">
          {moment(latestMessage?.created_at).calendar()}
        </div>
      </div>
    </div>
  );
};

export default UserChat;
