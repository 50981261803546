import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

const Coins = ({ toggleDarkMode, darkMode }) => {
  const [coins, setCoins] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentCoin, setCurrentCoin] = useState(null);
  const [newCoin, setNewCoin] = useState({
    name: "",
    availability: "Available",
    purchasePrice: "",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        const response = await axios.get(
          "https://ecoinwebback.tauschet.com/api/coins/getcoins"
        );
        setCoins(response.data);
      } catch (error) {
        console.error("Error fetching coins:", error);
      }
    };

    fetchCoins();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddCoin = async () => {
    try {
      const response = await axios.post(
        "https://ecoinwebback.tauschet.com/api/coins/addcoin",
        newCoin
      );
      setCoins([...coins, { ...newCoin, id: response.data.coinId }]);
      setShowModal(false);
      setNewCoin({ name: "", availability: "Available", purchasePrice: "" });
    } catch (error) {
      console.error("Error adding coin:", error);
    }
  };

  const handleEditCoin = async (id) => {
    try {
      const response = await axios.get(
        `https://ecoinwebback.tauschet.com/api/coins/getcoins/${id}`
      );
      setCurrentCoin(response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching coin:", error);
    }
  };

  const handleDeleteCoin = async (id) => {
    try {
      await axios.delete(
        `https://ecoinwebback.tauschet.com/api/coins/deletecoins/${id}`
      );
      setCoins(coins.filter((coin) => coin.cid !== id));
    } catch (error) {
      console.error("Error deleting coin:", error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(
        `https://ecoinwebback.tauschet.com/api/coins/updatecoins/${currentCoin.cid}`,
        currentCoin
      );
      setCoins(
        coins.map((coin) => (coin.cid === currentCoin.cid ? currentCoin : coin))
      );
      setShowModal(false);
      setCurrentCoin(null);
    } catch (error) {
      console.error("Error updating coin:", error);
    }
  };

  return (
    <div
      className={`flex h-screen ${
        darkMode ? "bg-gray-800 text-black" : "bg-gray-100 text-gray-900"
      }`}
    >
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow">
        <Header
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-grow p-20 overflow-auto">
          <div className="container mx-auto">
            <header className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-bold">Coins</h1>
              <button
                onClick={() => setShowModal(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add Coin
              </button>
            </header>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow-md">
                <thead className="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Name</th>
                    <th className="py-2 px-4 border-b text-left">
                      Availability
                    </th>
                    {/* <th className="py-2 px-4 border-b text-left">Purchase Price</th> */}
                    <th className="py-2 px-4 border-b text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {coins.map((coin) => (
                    <tr key={coin.cid}>
                      <td className="py-2 px-4 border-b">{coin.name}</td>
                      <td className="py-2 px-4 border-b">
                        {coin.availability}
                      </td>
                      {/* <td className="py-2 px-4 border-b">{coin.buy_price}</td> */}
                      <td className="py-2 px-4 border-b">
                        <button
                          onClick={() => handleEditCoin(coin.cid)}
                          className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 transition mr-2"
                        >
                          Edit
                        </button>
                        {/* <button 
                          onClick={() => handleDeleteCoin(coin.cid)} 
                          className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition"
                        >
                          Delete
                        </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>

        {/* Add/Edit Coin Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">
                {currentCoin ? "Edit Coin" : "Add Coin"}
              </h2>
              <form>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Name</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={currentCoin ? currentCoin.name : newCoin.name}
                    onChange={(e) =>
                      currentCoin
                        ? setCurrentCoin({
                            ...currentCoin,
                            name: e.target.value,
                          })
                        : setNewCoin({ ...newCoin, name: e.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Availability
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={
                      currentCoin
                        ? currentCoin.availability
                        : newCoin.availability
                    }
                    onChange={(e) =>
                      currentCoin
                        ? setCurrentCoin({
                            ...currentCoin,
                            availability: e.target.value,
                          })
                        : setNewCoin({
                            ...newCoin,
                            availability: e.target.value,
                          })
                    }
                  >
                    <option value="Available">Available</option>
                    <option value="Unavailable">Unavailable</option>
                  </select>
                </div>
                {/* <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Purchase Price</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={currentCoin ? currentCoin.buy_price : newCoin.buy_price}
                    onChange={(e) => currentCoin ? setCurrentCoin({ ...currentCoin, buy_price: e.target.value }) : setNewCoin({ ...newCoin, buy_price: e.target.value })}
                  />
                </div> */}
              </form>
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    currentCoin ? handleSaveEdit() : handleAddCoin()
                  }
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  {currentCoin ? "Save Changes" : "Add Coin"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Coins;
