import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { useCryptoState } from '../../context/AuthContext'; // Import useCryptoState to access logout function
import LinkItem from './LinkItem';
import { FaBitcoin } from 'react-icons/fa';
import {
  MdCurrencyExchange,
  MdMessage,
  MdAdminPanelSettings,
} from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { TbTransactionDollar } from "react-icons/tb";

const Sidebar = ({ isSidebarOpen }) => {
  const { logout, admin } = useCryptoState(); 
  const navigate = useNavigate();
  
  const handleSignOut = () => {
    logout(); 
    navigate('/login'); 
  };


  const links = [
    {
      href: "coins",
      icon: FaBitcoin,
      text: "Coins",
    },
    {
      href: "currency",
      icon: MdCurrencyExchange,
      text: "Currency",
    },
    {
      href: "message",
      icon: MdMessage,
      text: "Message",
    },
    {
      href: "profile",
      icon: CgProfile,
      text: "Profile",
    },
  ];

  if (admin && admin.privilege !== "admin") {
    links.push(
      {
        href: "admins",
        icon: MdAdminPanelSettings,
        text: "Administrators",
      },
      {
        href: "transaction",
        icon: TbTransactionDollar,
        text: "Transaction",
      }
    );
  }

  return (
    <aside
      className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700 transform transition-transform duration-300 ease-in-out ${
        isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
    >
      <div className="h-full px-3 pb-4 overflow-y-auto">
        <ul className="space-y-2 font-medium">
          {links.map((link, index) => (
            <LinkItem key={index} {...link} />
          ))}
        </ul>
        <button
          onClick={handleSignOut}
          className="w-full mt-4 p-2 text-center text-white bg-red-500 rounded-lg hover:bg-red-600 dark:bg-red-700 dark:hover:bg-red-800"
        >
          Sign Out
        </button>
      </div>
    </aside>
  );
};

export default Sidebar;
