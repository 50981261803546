import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import axios from "axios";

const ProfilePage = ({ toggleDarkMode, darkMode, admin }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bankAddresses, setBankAddresses] = useState([]);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [newBankName, setNewBankName] = useState("");
  const [newBankAddress, setNewBankAddress] = useState("");
  const [newWalletName, setNewWalletName] = useState("");
  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [showBankModal, setShowBankModal] = useState(false);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [showOldPasswordModal, setShowOldPasswordModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const API_BASE_URL = "https://ecoinwebback.tauschet.com/api/profile";

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/adminprofile/${admin.id}`
      );
      const { name, phone_num, accounts } = response.data;

      const bankAddresses = accounts
        .filter((acc) => acc.type === "bank")
        .map((acc) => ({
          id: acc.acc_id,
          name: acc.acc_name,
          address: acc.identification,
        }));
      const walletAddresses = accounts
        .filter((acc) => acc.type === "wallet")
        .map((acc) => ({
          id: acc.acc_id,
          name: acc.acc_name,
          address: acc.identification,
        }));

      setName(name);
      setPhoneNumber(phone_num);
      setBankAddresses(bankAddresses);
      setWalletAddresses(walletAddresses);
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };

  useEffect(() => {
    if (admin?.id) {
      fetchProfileData();
    }
  }, [admin?.id]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddBankAddress = async () => {
    if (newBankName && newBankAddress) {
      try {
        await axios.post(`${API_BASE_URL}/add-bank-address`, {
          adminId: admin.id,
          name: newBankName,
          address: newBankAddress,
        });
        setNewBankName("");
        setNewBankAddress("");
        setShowBankModal(false);
        fetchProfileData(); // Refresh data after adding
      } catch (error) {
        console.error("Error adding bank address:", error);
      }
    }
  };

  const handleAddWalletAddress = async () => {
    if (newWalletName && newWalletAddress) {
      try {
        await axios.post(`${API_BASE_URL}/add-wallet-address`, {
          adminId: admin.id,
          name: newWalletName,
          address: newWalletAddress,
        });
        setNewWalletName("");
        setNewWalletAddress("");
        setShowWalletModal(false);
        fetchProfileData(); // Refresh data after adding
      } catch (error) {
        console.error("Error adding wallet address:", error);
      }
    }
  };

  const handleRemoveAddress = async (id, type) => {
    try {
      await axios.delete(`${API_BASE_URL}/remove-address/${id}/${type}`);
      fetchProfileData(); // Refresh data after removal
    } catch (error) {
      console.error(`Error removing ${type} address:`, error);
    }
  };

  const handleCheckOldPassword = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/check-old-password`, {
        adminId: admin.id,
        oldPassword,
      });
      if (response.data.success) {
        setShowOldPasswordModal(false);
        setShowNewPasswordModal(true);
      } else {
        alert("Old password is incorrect.");
        setOldPassword("");
      }
    } catch (error) {
      console.error("Error checking old password:", error);
    }
  };

  const handleChangePasswordClick = () => {
    if (admin?.name === null) {
      setShowOldPasswordModal(false);

      setShowNewPasswordModal(true);
      fetchProfileData();
    } else {
      setShowOldPasswordModal(true);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword === confirmNewPassword) {
      try {
        await axios.post(`${API_BASE_URL}/change-password`, {
          adminId: admin.id,
          newPassword,
        });
        alert("Password changed successfully!");
        setShowNewPasswordModal(false);
      } catch (error) {
        console.error("Error changing password:", error);
        setNewPassword("");
        setConfirmNewPassword("");
      }
    } else {
      alert("New passwords do not match.");
      setNewPassword("");
      setConfirmNewPassword("");
    }
  };

  const handleSaveChanges = async () => {
    if (name && phoneNumber) {
      try {
        await axios.post(`${API_BASE_URL}/update-profile`, {
          adminId: admin.id,
          name,
          phoneNumber,
        });
        alert("Profile updated successfully!");
        fetchProfileData(); // Refresh data after update
      } catch (error) {
        console.error("Error updating profile:", error);
      }
    } else {
      alert("Please fill in both fields.");
    }
  };

  return (
    <div
      className={`flex h-screen ${
        darkMode ? "bg-gray-800 text-black" : "bg-gray-100 text-gray-900"
      }`}
    >
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow">
        <Header
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleSidebar={toggleSidebar}
        />

        <div className="p-8 flex flex-col items-center">
          <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-full max-w-2xl">
            <h1 className="text-3xl font-semibold mb-6">My Profile</h1>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300">
                Name
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-2 mt-2 border border-gray-300 dark:border-gray-600 rounded-lg dark:bg-gray-700 dark:text-gray-200"
              />
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 dark:text-gray-300">
                Phone Number
              </label>
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="w-full p-2 mt-2 border border-gray-300 dark:border-gray-600 rounded-lg dark:bg-gray-700 dark:text-gray-200"
              />
            </div>

            <button
              onClick={handleSaveChanges}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
            >
              Save Changes
            </button>

            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-2">Bank Addresses</h2>
              <ul>
                {bankAddresses.length > 0 ? (
                  bankAddresses.map((address, index) => (
                    <li
                      key={address.id || index}
                      className="text-gray-700 dark:text-gray-300"
                    >
                      {address.name}: {address.address}
                      <button
                        onClick={() => handleRemoveAddress(address.id, "bank")}
                        className="ml-2 text-red-500"
                      >
                        Remove
                      </button>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-700 dark:text-gray-300">
                    No bank addresses available
                  </li>
                )}
              </ul>
              <button
                onClick={() => setShowBankModal(true)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add Bank Address
              </button>
            </div>

            <div className="mt-6">
              <h2 className="text-xl font-semibold mb-2">Wallet Addresses</h2>
              <ul>
                {walletAddresses.length > 0 ? (
                  walletAddresses.map((address, index) => (
                    <li
                      key={address.id || index}
                      className="text-gray-700 dark:text-gray-300"
                    >
                      {address.name}: {address.address}
                      <button
                        onClick={() =>
                          handleRemoveAddress(address.id, "wallet")
                        }
                        className="ml-2 text-red-500"
                      >
                        Remove
                      </button>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-700 dark:text-gray-300">
                    No wallet addresses available
                  </li>
                )}
              </ul>
              <button
                onClick={() => setShowWalletModal(true)}
                className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add Wallet Address
              </button>
            </div>

            <button
              onClick={handleChangePasswordClick}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition mt-6"
            >
              Change Password
            </button>
          </div>
        </div>

        {/* Bank Address Modal */}
        {showBankModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Add Bank Address</h2>
              <input
                type="text"
                placeholder="Bank Name"
                value={newBankName}
                onChange={(e) => setNewBankName(e.target.value)}
                className="w-full p-2 mb-2 border border-gray-300 rounded-lg"
              />
              <input
                type="text"
                placeholder="Bank Address"
                value={newBankAddress}
                onChange={(e) => setNewBankAddress(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
              />
              <button
                onClick={handleAddBankAddress}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add
              </button>
              <button
                onClick={() => setShowBankModal(false)}
                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Wallet Address Modal */}
        {showWalletModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Add Wallet Address</h2>
              <input
                type="text"
                placeholder="Wallet Name"
                value={newWalletName}
                onChange={(e) => setNewWalletName(e.target.value)}
                className="w-full p-2 mb-2 border border-gray-300 rounded-lg"
              />
              <input
                type="text"
                placeholder="Wallet Address"
                value={newWalletAddress}
                onChange={(e) => setNewWalletAddress(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
              />
              <button
                onClick={handleAddWalletAddress}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add
              </button>
              <button
                onClick={() => setShowWalletModal(false)}
                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* Old Password Modal */}
        {showOldPasswordModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Enter Old Password</h2>
              <input
                type="password"
                placeholder="Old Password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
              />
              <button
                onClick={handleCheckOldPassword}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Check
              </button>
              <button
                onClick={() => setShowOldPasswordModal(false)}
                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {/* New Password Modal */}
        {showNewPasswordModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">Change Password</h2>
              <input
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 mb-2 border border-gray-300 rounded-lg"
              />
              <input
                type="password"
                placeholder="Confirm New Password"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg"
              />
              <button
                onClick={handleChangePassword}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Change Password
              </button>
              <button
                onClick={() => setShowNewPasswordModal(false)}
                className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
