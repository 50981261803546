import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";
import axios from "axios"; // Ensure axios is installed and imported
import AuthContext, { useCryptoState } from "../context/AuthContext";

const Transaction = ({ toggleDarkMode, darkMode }) => {
  const [transactions, setTransactions] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const { admin } = useCryptoState();

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(
        "https://ecoinwebback.tauschet.com/api/coins/transactions"
      );
      setTransactions(response.data);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleRowClick = (transaction) => {
    setSelectedTransaction(transaction);
    setShowModal(true);
  };

  return (
    <div
      className={`flex h-screen ${
        darkMode ? "bg-gray-800 text-white" : "bg-gray-100 text-gray-900"
      }`}
    >
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow">
        <Header
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleSidebar={toggleSidebar}
        />
        <div className="flex-grow p-20 overflow-auto">
          <div className="container mx-auto">
            <header className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-bold">Transactions</h1>
            </header>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow-md">
                <thead className="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Coin Name</th>
                    {/* <th className="py-2 px-4 border-b text-left">Price</th> */}
                    <th className="py-2 px-4 border-b text-left">Type</th>
                    <th className="py-2 px-4 border-b text-left">Admin</th>
                    <th className="py-2 px-4 border-b text-left">Customer</th>
                    <th className="py-2 px-4 border-b text-left">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr
                      key={transaction.id}
                      onClick={() => handleRowClick(transaction)}
                      className="cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-600 transition"
                    >
                      <td className="py-2 px-4 border-b">
                        {transaction.coin_name}
                      </td>
                      {/* <td className="py-2 px-4 border-b">{transaction.price}</td> */}
                      <td className="py-2 px-4 border-b">{transaction.type}</td>
                      <td className="py-2 px-4 border-b">
                        {transaction.admin_name}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {transaction.customer_name}
                      </td>
                      <td className="py-2 px-4 border-b">{transaction.date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Transaction Details Modal */}
          {showModal && selectedTransaction && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">
                  Transaction Details
                </h2>
                <p>
                  <strong>Coin Name:</strong> {selectedTransaction.coin_name}
                </p>
                <p>
                  <strong>Price:</strong> {selectedTransaction.price}
                </p>
                <p>
                  <strong>Type:</strong> {selectedTransaction.type}
                </p>
                <p>
                  <strong>Admin:</strong> {selectedTransaction.admin_name}
                </p>
                <p>
                  <strong>Admin Phone Number:</strong>{" "}
                  {selectedTransaction.admin_phone_num}
                </p>
                <p>
                  <strong>Customer:</strong> {selectedTransaction.customer_name}
                </p>
                <p>
                  <strong>Customer Phone Number:</strong>{" "}
                  {selectedTransaction.customer_phone_num}
                </p>
                <p>
                  <strong>Date:</strong> {selectedTransaction.date}
                </p>
                <div className="flex justify-end gap-2 mt-4">
                  <button
                    onClick={() => setShowModal(false)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transaction;
