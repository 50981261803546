import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header/Header";

const Currency = ({ toggleDarkMode, darkMode }) => {
  const [currencies, setCurrencies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [newCurrency, setNewCurrency] = useState({
    name: "",
    availability: "Available",
    buy: "",
    sell: "",
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const API_BASE_URL = "https://ecoinwebback.tauschet.com/api/coins";

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchCurrencies = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/getcurrencies`);
      setCurrencies(response.data);
    } catch (error) {
      console.error("Error fetching currencies:", error);
    }
  };

  const handleAddCurrency = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/addcurrency`,
        newCurrency
      );
      setCurrencies([...currencies, response.data]);
      setShowModal(false);
      setNewCurrency({
        name: "",
        availability: "Available",
        buy: "",
        sell: "",
      });
    } catch (error) {
      console.error("Error adding currency:", error);
    }
  };

  const handleEditCurrency = (id) => {
    const currency = currencies.find((currency) => currency.curr_id === id);
    setCurrentCurrency(currency);
    setShowModal(true);
  };

  const handleDeleteCurrency = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/deletecurrency/${id}`);
      setCurrencies(currencies.filter((currency) => currency.curr_id !== id));
    } catch (error) {
      console.error("Error deleting currency:", error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/updatecurrency/${currentCurrency.curr_id}`,
        currentCurrency
      );
      setCurrencies(
        currencies.map((currency) =>
          currency.curr_id === currentCurrency.curr_id
            ? response.data
            : currency
        )
      );
      setShowModal(false);
      setCurrentCurrency(null);
    } catch (error) {
      console.error("Error saving currency changes:", error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div
      className={`flex h-screen ${
        darkMode ? "bg-gray-800 text-black" : "bg-gray-100 text-gray-900"
      }`}
    >
      <Sidebar isSidebarOpen={isSidebarOpen} />
      <div className="flex flex-col flex-grow">
        <Header
          toggleDarkMode={toggleDarkMode}
          darkMode={darkMode}
          toggleSidebar={toggleSidebar}
        />
        <main className="flex-grow p-20 overflow-auto">
          <div className="container mx-auto">
            <header className="flex items-center justify-between mb-4">
              <h1 className="text-2xl font-bold">Currencies</h1>
              <button
                onClick={() => setShowModal(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
              >
                Add Currency
              </button>
            </header>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white dark:bg-gray-800 rounded-lg shadow-md">
                <thead className="bg-gray-100 dark:bg-gray-700">
                  <tr>
                    <th className="py-2 px-4 border-b text-left">Name</th>
                    <th className="py-2 px-4 border-b text-left">Buy Price</th>
                    <th className="py-2 px-4 border-b text-left">Sell Price</th>
                    <th className="py-2 px-4 border-b text-left">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currencies.map((currency) => (
                    <tr key={currency.curr_id}>
                      <td className="py-2 px-4 border-b">{currency.name}</td>
                      <td className="py-2 px-4 border-b">{currency.buy}</td>
                      <td className="py-2 px-4 border-b">{currency.sell}</td>
                      <td className="py-2 px-4 border-b">
                        <button
                          onClick={() => handleEditCurrency(currency.curr_id)}
                          className="bg-yellow-500 text-white px-2 py-1 rounded-lg hover:bg-yellow-600 transition mr-2"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteCurrency(currency.curr_id)}
                          className="bg-red-500 text-white px-2 py-1 rounded-lg hover:bg-red-600 transition"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </main>

        {/* Add/Edit Currency Modal */}
        {showModal && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 w-full max-w-md">
              <h2 className="text-xl font-semibold mb-4">
                {currentCurrency ? "Edit Currency" : "Add Currency"}
              </h2>
              <form>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">Name</label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={
                      currentCurrency ? currentCurrency.name : newCurrency.name
                    }
                    onChange={(e) =>
                      currentCurrency
                        ? setCurrentCurrency({
                            ...currentCurrency,
                            name: e.target.value,
                          })
                        : setNewCurrency({
                            ...newCurrency,
                            name: e.target.value,
                          })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Availability
                  </label>
                  <select
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={
                      currentCurrency
                        ? currentCurrency.availability
                        : newCurrency.availability
                    }
                    onChange={(e) =>
                      currentCurrency
                        ? setCurrentCurrency({
                            ...currentCurrency,
                            availability: e.target.value,
                          })
                        : setNewCurrency({
                            ...newCurrency,
                            availability: e.target.value,
                          })
                    }
                  >
                    <option value="Available">Available</option>
                    <option value="Unavailable">Unavailable</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Buy Price
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={
                      currentCurrency ? currentCurrency.buy : newCurrency.buy
                    }
                    onChange={(e) =>
                      currentCurrency
                        ? setCurrentCurrency({
                            ...currentCurrency,
                            buy: e.target.value,
                          })
                        : setNewCurrency({
                            ...newCurrency,
                            buy: e.target.value,
                          })
                    }
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium mb-2">
                    Sell Price
                  </label>
                  <input
                    type="text"
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                    value={
                      currentCurrency ? currentCurrency.sell : newCurrency.sell
                    }
                    onChange={(e) =>
                      currentCurrency
                        ? setCurrentCurrency({
                            ...currentCurrency,
                            sell: e.target.value,
                          })
                        : setNewCurrency({
                            ...newCurrency,
                            sell: e.target.value,
                          })
                    }
                  />
                </div>
              </form>
              <div className="flex justify-end gap-2">
                <button
                  onClick={() => setShowModal(false)}
                  className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                >
                  Close
                </button>
                <button
                  onClick={() =>
                    currentCurrency ? handleSaveEdit() : handleAddCurrency()
                  }
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  {currentCurrency ? "Save Changes" : "Add Currency"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Currency;
