import React, { useContext, createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState(null); 
  const [isLoggedIn, setIsLoggedIn] = useState(false); 

  
  const login = (adminData) => {
    setAdmin(adminData);
    localStorage.setItem('admin', JSON.stringify(adminData));
    setIsLoggedIn(true); 
  };

  
  const logout = () => {
    setAdmin(null);
    localStorage.removeItem('admin');
    setIsLoggedIn(false);
  };

  
  useEffect(() => {
    const savedAdmin = localStorage.getItem('admin');
    if (savedAdmin) {
      setAdmin(JSON.parse(savedAdmin));
      setIsLoggedIn(true);
    }
  }, []);

 
  useEffect(() => {
  }, [admin]);

  return (
    <AuthContext.Provider value={{ admin, login, logout, isLoggedIn }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useCryptoState = () => {
  return useContext(AuthContext);
};
