import React, { useState } from "react";
import { useCryptoState } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  // State for form fields
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const { login } = useCryptoState();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      alert("Please fill in both fields");
      return;
    }

    try {
      const response = await axios.post(
        "https://ecoinwebback.tauschet.com/api/auth/adminlogin",
        {
          email,
          password,
        }
      );

      if (response.data.success) {
        const userData = response.data.admin;

        if (response.data.warning) {
          alert(response.data.warning);
        }

        login(userData);
        navigate("/coins");
      } else {
        alert("Login failed. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          alert(error.response.data.error);
        } else if (error.response.status === 500) {
          alert("Server error. Please try again later.");
        }
      } else {
        console.error("Login error:", error);
        alert("Login failed. Please check your connection or try again.");
      }
    }
  };

  return (
    <div className="bg-gray-100 px-12 py-16 rounded-lg border border-gray-300 shadow-lg max-w-lg mx-auto">
      <h1 className="text-4xl font-semibold text-center mb-8 text-gray-700 font-quickSand">
        Welcome Back
      </h1>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Email
          </label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-4 mt-1 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
            placeholder="admin@example.com"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Password
          </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-4 mt-1 bg-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition"
            placeholder="••••••••"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white text-lg font-semibold py-3 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
        >
          Sign In
        </button>
      </form>
    </div>
  );
};

export default Login;
