import React, { useContext, useState, useEffect, useRef } from 'react';
import { useCryptoState } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useFetchRecipientUser } from '../../hooks/useFetchRecipient';
import moment from 'moment';
import InputEmoji from 'react-input-emoji';
import axios from 'axios';
import { baseUrl } from '../../utils/services'; // Ensure this path is correct

const ChatBox = () => {
  const { admin } = useCryptoState();
  const { currentChat, messages, isMessageLoading, sendTextMessage } = useContext(ChatContext);
  const { recipientUser } = useFetchRecipientUser(currentChat, admin);
  const [textMessage, setTextMessage] = useState("");
  const [image, setImage] = useState(null); // State to hold the selected image file
  const [coinInfo, setCoinInfo] = useState(null);
  const scroll = useRef();

  useEffect(() => {
    scroll.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  useEffect(() => {
    // Fetch coin information when itemId changes
    const fetchCoinInfo = async () => {
      if (!messages || !messages[0]?.item_id) return; // Ensure messages is not empty and item_id is available

      try {
        const response = await axios.get(`${baseUrl}/chat/coins/${messages[0].item_id}`);
        setCoinInfo(response.data);
      } catch (error) {
        console.error('Error fetching coin info:', error);
      }
    };

    fetchCoinInfo();
  }, [messages]);

  // Function to handle chat termination
  const handleTerminateChat = async () => {
    try {
      await axios.put(`${baseUrl}/chat/terminate/${currentChat[0]?.id}`);
      alert('Chat terminated successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error terminating chat:', error);
    }
  };

  // Function to handle closing the deal
  const handleCloseDeal = async () => {
    const cid = coinInfo?.cid;
    const buy_price = coinInfo?.buy_price;
    const chatId = currentChat[0]?.id;
    const members = JSON.parse(currentChat[0]?.members || '[]');
    const userId = members[0];  // The first member is always the user
    const adminId = members[1]; // The second member is the admin
    const type = "sell";

    const requestBody = {
      cid,
      buy_price,
      chatId,
      userId,
      adminId,
      type
    };

    try {
      await axios.put(`${baseUrl}/chat/close-deal/${chatId}`, requestBody);
      alert('Deal closed successfully');
      window.location.reload();
    } catch (error) {
      console.error('Error closing deal:', error);
    }
  };

  if (!recipientUser) {
    return (
      <p className="text-center w-full">
        No Conversation Selected Yet.....
      </p>
    );
  }

  if (isMessageLoading) {
    return (
      <p className="text-center w-full">
        Loading Chat.....
      </p>
    );
  }

  return (
    <div className="flex flex-col h-full gap-4">
      <div className="chat-header font-bold text-xl py-2 px-4 border-b dark:border-gray-600">
        {recipientUser?.name}
      </div>
      {coinInfo && (
        <div className="coin-info p-4 bg-gray-800 text-white rounded-lg mb-4">
          <h4 className="text-yellow-400 text-sm font-medium mb-1">Coin Information:</h4>
          <p><strong>Name:</strong> {coinInfo.name}</p>
          {/* <p><strong>Price:</strong> {coinInfo.buy_price}</p> */}
          <p><strong>Only trade {coinInfo.name} here.</strong></p>
          <p>Thanks for your cooperation!</p>
          <div className="flex justify-end mt-4 space-x-2">
            <button
              className="bg-red-500 text-white py-2 px-4 rounded-lg hover:bg-red-600"
              onClick={handleTerminateChat}
            >
              Terminate Chat
            </button>
            <button
              className="bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600"
              onClick={handleCloseDeal}
            >
              Close Deal
            </button>
          </div>
        </div>
      )}

      <div className="messages flex-grow overflow-y-auto px-4">
        {Array.isArray(messages) && messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.senderId === String(admin.id) ? 'justify-end' : 'justify-start'} mb-3`}
          >
            <div
              className={`message p-3 rounded-lg max-w-xs ${message.senderId === String(admin.id) ? 'bg-blue-700 text-white' : 'bg-gray-300 dark:bg-gray-700 text-black dark:text-gray-200'}`}
              ref={scroll}
            >
              {message.image && (
                <img
                  src={message.image}
                  alt="sent content"
                  className="sent-image max-w-full rounded-lg mb-2"
                />
              )}
              <span>{message.text}</span>
              <div className="text-sm text-right text-yellow-600 dark:text-yellow-500 mt-1">
                {moment(message.created_at).calendar()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="chat-input flex items-center gap-3 p-3 border-t dark:border-gray-600">
        <InputEmoji
          value={textMessage}
          onChange={setTextMessage}
          fontFamily='nunito'
          borderColor='rgba(72,112,223,0.2)'
          className="flex-grow"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          className="file-input"
        />
        <button
          className="send-btn bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 transition duration-200"
          onClick={() => sendTextMessage(coinInfo.cid,textMessage, String(admin.id), currentChat?.[0]?.id, image, setTextMessage)}
        >

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-send-fill" viewBox="0 0 16 16">
            <path d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471z"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
