import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../utils/services";

export const useFetchRecipientUser = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [error, setError] = useState(null);

  const recipientUserId = (chat && chat.length > 0 && user && user.id) 
    ? (() => {
        try {
          const members = JSON.parse(chat[0]?.members);
          return members.find(memberId => memberId !== String(user.id));
        } catch (error) {
          console.error("Failed to parse members:", error);
          setError("Invalid members data");
          return null;
        }
      })()
    : null;

  useEffect(() => {
    if (recipientUserId) {
      axios
        .get(`${baseUrl}/auth/finduserasadmin/${recipientUserId}`)
        .then((response) => setRecipientUser(response.data))
        .catch((err) => setError(err));
    }
  }, [recipientUserId]);

  return { recipientUser, error };
};
